import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { useState } from 'react';
import { Table } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const TableExample = () => {
  const cols = [{
    key: 'id',
    headerName: 'Not rendered'
  }, {
    key: 'firstName',
    headerName: 'First name'
  }, {
    key: 'surname',
    headerName: 'Surname'
  }, {
    key: 'age',
    headerName: 'Age',
    transform: ({
      age
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{age}</div>;
    }
  }, {
    key: 'profession',
    headerName: 'Profession'
  }];
  const rows = [{
    id: 1000,
    firstName: 'Lauri',
    surname: 'Kekkonen',
    age: 39,
    profession: 'Engineer'
  }, {
    id: 1001,
    firstName: 'Maria',
    surname: 'Sarasoja',
    age: 62,
    profession: 'Designer'
  }, {
    id: 1002,
    firstName: 'Anneli',
    surname: 'Routa',
    age: 50,
    profession: 'Meteorologist'
  }, {
    id: 1003,
    firstName: 'Osku',
    surname: 'Rausku',
    age: 18,
    profession: 'Mail Carrier'
  }];
  const captionDark = <span>
      <b>Table 1</b>: Service users (dark variant)
    </span>;
  const captionLight = <span>
      <b>Table 2</b>: Service users (light variant)
    </span>;
  return <div style={{
    maxWidth: '640px'
  }}>
      <Table cols={cols} rows={rows} caption={captionDark} indexKey="id" renderIndexCol={false} mdxType="Table" />
    </div>;
};
export const TableDefaultExample = () => {
  const cols = [{
    key: 'id',
    headerName: 'Not rendered'
  }, {
    key: 'firstName',
    headerName: 'First name'
  }, {
    key: 'surname',
    headerName: 'Surname'
  }, {
    key: 'age',
    headerName: 'Age',
    transform: ({
      age
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{age}</div>;
    }
  }, {
    key: 'profession',
    headerName: 'Profession'
  }];
  const rows = [{
    id: 1000,
    firstName: 'Lauri',
    surname: 'Kekkonen',
    age: 39,
    profession: 'Engineer'
  }, {
    id: 1001,
    firstName: 'Maria',
    surname: 'Sarasoja',
    age: 62,
    profession: 'Designer'
  }, {
    id: 1002,
    firstName: 'Anneli',
    surname: 'Routa',
    age: 50,
    profession: 'Meteorologist'
  }, {
    id: 1003,
    firstName: 'Osku',
    surname: 'Rausku',
    age: 18,
    profession: 'Mail Carrier'
  }];
  const captionDark = <span>
      <b>Table 2</b>: Service users (dark variant)
    </span>;
  const captionLight = <span>
      <b>Table 3</b>: Service users (light variant)
    </span>;
  return <div style={{
    maxWidth: '640px'
  }}>
      <Table cols={cols} rows={rows} caption={captionDark} indexKey="id" renderIndexCol={false} mdxType="Table" />
      <div style={{
      marginTop: 'var(--spacing-l)'
    }}>
        <Table cols={cols} rows={rows} caption={captionLight} variant="light" indexKey="id" renderIndexCol={false} mdxType="Table" />
      </div>
    </div>;
};
export const VerticalHeadersExample = () => {
  const cols = [{
    key: 'id',
    headerName: 'Not rendered'
  }, {
    key: '8-12',
    headerName: '8-12'
  }, {
    key: '12-14',
    headerName: '12-14'
  }, {
    key: '14-16',
    headerName: '14-16'
  }, {
    key: '16-18',
    headerName: '16-18'
  }];
  const unitRows = [{
    id: 'monday',
    '8-12': 324,
    '12-14': 562,
    '14-16': 280,
    '16-18': 94
  }, {
    id: 'tuesday',
    '8-12': 341,
    '12-14': 688,
    '14-16': 425,
    '16-18': 113
  }, {
    id: 'wednesday',
    '8-12': 294,
    '12-14': 492,
    '14-16': 280,
    '16-18': 67
  }, {
    id: 'thursday',
    '8-12': 312,
    '12-14': 501,
    '14-16': 455,
    '16-18': 112
  }, {
    id: 'friday',
    '8-12': 150,
    '12-14': 142,
    '14-16': 362,
    '16-18': 455
  }];
  const verticalHeaders = [{
    key: 'monday',
    headerName: 'Monday'
  }, {
    key: 'tuesday',
    headerName: 'Tuesday'
  }, {
    key: 'wednesday',
    headerName: 'Wednesday'
  }, {
    key: 'thursday',
    headerName: 'Thursday'
  }, {
    key: 'friday',
    headerName: 'Friday'
  }];
  const caption = <span>
      <b>Table 4</b>: Units sold by weekday
    </span>;
  return <div style={{
    maxWidth: '486px'
  }}>
      <Table verticalHeaders={verticalHeaders} cols={cols} rows={unitRows} caption={caption} textAlignContentRight indexKey="id" renderIndexCol={false} mdxType="Table" />
    </div>;
};
export const ZebraExample = () => {
  const cols = [{
    key: 'id',
    headerName: 'Not rendered'
  }, {
    key: 'firstName',
    headerName: 'First name'
  }, {
    key: 'surname',
    headerName: 'Surname'
  }, {
    key: 'age',
    headerName: 'Age',
    transform: ({
      age
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{age}</div>;
    }
  }, {
    key: 'city',
    headerName: 'City'
  }, {
    key: 'profession',
    headerName: 'Profession'
  }, {
    key: 'experience',
    headerName: 'Experience (years)',
    transform: ({
      experience
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{experience}</div>;
    }
  }];
  const rows = [{
    id: 1000,
    firstName: 'Lauri',
    surname: 'Kekkonen',
    age: 39,
    city: 'Helsinki',
    profession: 'Engineer',
    experience: 10
  }, {
    id: 1001,
    firstName: 'Maria',
    surname: 'Sarasoja',
    age: 62,
    city: 'Tampere',
    profession: 'Designer',
    experience: 39
  }, {
    id: 1002,
    firstName: 'Anneli',
    surname: 'Routa',
    age: 50,
    city: 'Turku',
    profession: 'Meteorologist',
    experience: 25
  }, {
    id: 1003,
    firstName: 'Osku',
    surname: 'Rausku',
    age: 18,
    city: 'Oulu',
    profession: 'Mail Carrier',
    experience: 1
  }];
  const caption = <span>
      <b>Table 5</b>: Service users (zebra variant)
    </span>;
  return <div style={{
    maxWidth: '800px'
  }}>
      <Table rows={rows} cols={cols} caption={caption} zebra indexKey="id" renderIndexCol={false} mdxType="Table" />
    </div>;
};
export const VerticalExample = () => {
  const cols = [{
    key: 'day',
    headerName: 'Day'
  }, {
    key: 'product1',
    headerName: 'Product 1',
    transform: ({
      product1
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{product1}</div>;
    }
  }, {
    key: 'product2',
    headerName: 'Product 2',
    transform: ({
      product2
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{product2}</div>;
    }
  }, {
    key: 'product3',
    headerName: 'Product 3',
    transform: ({
      product3
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{product3}</div>;
    }
  }];
  const rows = [{
    day: 'Monday',
    product1: 12,
    product2: 24,
    product3: 5
  }, {
    day: 'Tuesday',
    product1: 10,
    product2: 32,
    product3: 6
  }, {
    day: 'Wednesday',
    product1: 14,
    product2: 35,
    product3: 6
  }, {
    day: 'Thursday',
    product1: 11,
    product2: 18,
    product3: 2
  }, {
    day: 'Friday',
    product1: 16,
    product2: 45,
    product3: 12
  }];
  const caption = <span>
      <b>Table 6</b>: Units sold by weekday (vertical lines variant)
    </span>;
  return <div style={{
    maxWidth: '496px'
  }}>
      <Table verticalLines cols={cols} rows={rows} caption={caption} indexKey="day" renderIndexCol={true} mdxType="Table" />
    </div>;
};
export const DenseExample = () => {
  const cols = [{
    key: 'id',
    headerName: 'Not rendered'
  }, {
    key: 'firstName',
    headerName: 'First name'
  }, {
    key: 'surname',
    headerName: 'Surname'
  }, {
    key: 'age',
    headerName: 'Age',
    transform: ({
      age
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{age}</div>;
    }
  }, {
    key: 'profession',
    headerName: 'Profession'
  }];
  const rows = [{
    id: 1000,
    firstName: 'Lauri',
    surname: 'Kekkonen',
    age: 39,
    profession: 'Engineer'
  }, {
    id: 1001,
    firstName: 'Maria',
    surname: 'Sarasoja',
    age: 62,
    profession: 'Designer'
  }, {
    id: 1002,
    firstName: 'Anneli',
    surname: 'Routa',
    age: 50,
    profession: 'Meteorologist'
  }, {
    id: 1003,
    firstName: 'Osku',
    surname: 'Rausku',
    age: 18,
    profession: 'Mail Carrier'
  }];
  const caption = <span>
      <b>Table 7</b>: Service users (dense variant)
    </span>;
  return <div style={{
    maxWidth: '500px'
  }}>
      <Table cols={cols} rows={rows} dense caption={caption} indexKey="id" renderIndexCol={false} mdxType="Table" />
    </div>;
};
export const SortingExample = () => {
  const cols = [{
    key: 'id',
    headerName: 'Not rendered'
  }, {
    key: 'firstName',
    headerName: 'First name',
    isSortable: true
  }, {
    key: 'surname',
    headerName: 'Surname',
    isSortable: true
  }, {
    key: 'age',
    headerName: 'Age',
    sortIconType: 'other',
    transform: ({
      age
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{age}</div>;
    },
    isSortable: true
  }, {
    key: 'profession',
    headerName: 'Profession',
    isSortable: true
  }];
  const rows = [{
    id: 1000,
    firstName: 'Lauri',
    surname: 'Kekkonen',
    age: 39,
    profession: 'Engineer'
  }, {
    id: 1001,
    firstName: 'Maria',
    surname: 'Sarasoja',
    age: 62,
    profession: 'Designer'
  }, {
    id: 1002,
    firstName: 'Anneli',
    surname: 'Routa',
    age: 50,
    profession: 'Meteorologist'
  }, {
    id: 1003,
    firstName: 'Osku',
    surname: 'Rausku',
    age: 18,
    profession: 'Mail Carrier'
  }, {
    id: 1004,
    firstName: 'Linda',
    surname: 'Koululainen',
    age: 8,
    profession: 'School student'
  }];
  const caption = <span>
      <b>Table 8</b>: Service users (sorting, dark variant)
    </span>;
  return <div style={{
    maxWidth: '640px'
  }}>
      <Table ariaLabelSortButtonUnset="Not sorted" ariaLabelSortButtonAscending="Sorted in ascending order" ariaLabelSortButtonDescending="Sorted in descending order" indexKey="id" renderIndexCol={false} cols={cols} rows={rows} caption={caption} mdxType="Table" />
    </div>;
};
export const SelectionExample = () => {
  const cols = [{
    key: 'id',
    headerName: 'Not rendered'
  }, {
    key: 'firstName',
    headerName: 'First name'
  }, {
    key: 'surname',
    headerName: 'Surname'
  }, {
    key: 'age',
    headerName: 'Age',
    transform: ({
      age
    }) => {
      return <div style={{
        textAlign: 'right'
      }}>{age}</div>;
    }
  }, {
    key: 'profession',
    headerName: 'Profession'
  }];
  const rows = [{
    id: 1000,
    firstName: 'Lauri',
    surname: 'Kekkonen',
    age: 39,
    profession: 'Engineer'
  }, {
    id: 1001,
    firstName: 'Maria',
    surname: 'Sarasoja',
    age: 62,
    profession: 'Designer'
  }, {
    id: 1002,
    firstName: 'Anneli',
    surname: 'Routa',
    age: 50,
    profession: 'Meteorologist'
  }, {
    id: 1003,
    firstName: 'Osku',
    surname: 'Rausku',
    age: 18,
    profession: 'Mail Carrier'
  }];
  const [selectedRows, setSelectedRows] = useState([]);
  return <div style={{
    maxWidth: '640px'
  }}>
      <Table checkboxSelection selectedRows={selectedRows} setSelectedRows={setSelectedRows} heading="Employees" id="checkbox-selection" indexKey="id" renderIndexCol={false} cols={cols} rows={rows} selectAllRowsText="Select all rows" clearSelectionsText="Clear selections" ariaLabelCheckboxSelection="Row selection" mdxType="Table" />
    </div>;
};
export const _frontmatter = {
  "slug": "/components/table",
  "title": "Table",
  "navTitle": "Table"
};
const layoutProps = {
  TableExample,
  TableDefaultExample,
  VerticalHeadersExample,
  ZebraExample,
  VerticalExample,
  DenseExample,
  SortingExample,
  SelectionExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <TableExample mdxType="TableExample" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "basic-tables-core-and-react",
      "style": {
        "position": "relative"
      }
    }}>{`Basic tables (Core and React)`}<a parentName="h4" {...{
        "href": "#basic-tables-core-and-react",
        "aria-label": "basic tables core and react permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul">{`Use tables to organise and present data.`}</li>
      <li parentName="ul">{`You should consider other presentation formats when the data is very simple, e.g. when the table would only have less than 4 rows or less than 3 columns.`}
        <ul parentName="li">
          <li parentName="ul">{`Also note that HDS Tables are not a spreadsheet application. If you expect the user to need complex data operations it may be wise to share the data as a spreadsheet file.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Do not use tables to create layouts. Tables are meant to present data.`}</li>
      <li parentName="ul">{`Align text to the left and numerical data to the right. Header cells are always aligned to the left. HDS provides CSS classes to make this easier.`}</li>
      <li parentName="ul">{`Utilise zebra lines when your table has long rows. Zebra lines help the user to follow a specific row.`}</li>
      <li parentName="ul">{`Utilise vertical lines when your table has a lot of content and narrow cells. Number-heavy tables are an example of a situation where vertical lines may help the user.`}</li>
      <li parentName="ul">{`HDS Table offers smaller font sizes and spacing on mobile screens.`}
        <ul parentName="li">
          <li parentName="ul">{`If your table has a large amount of data, you can use the `}<inlineCode parentName="li">{`dense`}</inlineCode>{` property to force mobile spacing on all screen sizes.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Table container is allowed to be scrollable. This is common on smaller screens where it can be impossible to fit the full table on the device screen.`}
        <ul parentName="li">
          <li parentName="ul">{`On smaller screens, it may be beneficial to consider other presentation formats for table data. For example, a table can be broken into cards.`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "interactable-tables-react",
      "style": {
        "position": "relative"
      }
    }}>{`Interactable tables (React)`}<a parentName="h4" {...{
        "href": "#interactable-tables-react",
        "aria-label": "interactable tables react permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul">{`HDS Tables support sorting. When a column is set to be sortable, a sorting button appears after its header.`}
        <ul parentName="li">
          <li parentName="ul">{`The sorting icon type can be changed depending on the type of data in the column.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Only use sorting when it is necessary or otherwise beneficial to the user.`}</li>
      <li parentName="ul">{`HDS Table's rows can be made selectable. In this case, HDS automatically provides actions for selecting and deselecting all rows.`}</li>
      <li parentName="ul">{`If your table includes actions that can affect multiple rows (e.g. deleting selected rows or exporting a CSV file), these actions must be placed above the table. For more information, see `}<a parentName="li" {...{
          "href": "#selecting-rows"
        }}>{`Selecting rows`}</a>{` and `}<a parentName="li" {...{
          "href": "#custom-table-actions"
        }}>{`Custom actions`}</a>{` variant examples below.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The default HDS table variant includes horizontal lines. The header row can be either dark or light depending on the brand colour used as a background. Whether you need a black or white text colour can be checked from `}<InternalLink href="/foundation/design-tokens/colour/accessibility#brand-colour-combinations" mdxType="InternalLink">{`HDS Colour documentation page`}</InternalLink></p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <TableDefaultExample mdxType="TableDefaultExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "vertical-headers",
      "style": {
        "position": "relative"
      }
    }}>{`Vertical headers`}<a parentName="h4" {...{
        "href": "#vertical-headers",
        "aria-label": "vertical headers permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Vertical headers can be used to create tables that have both a header row and a header column. Note that the empty header cell on the top left corner has `}<inlineCode parentName="p">{`role="presentation"`}</inlineCode>{` attribute.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <VerticalHeadersExample mdxType="VerticalHeadersExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "zebra-rows",
      "style": {
        "position": "relative"
      }
    }}>{`Zebra rows`}<a parentName="h4" {...{
        "href": "#zebra-rows",
        "aria-label": "zebra rows permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Zebra rows (alternating row colours) should be used on tables that have very long rows. It makes it easier to visually scan the table and follow rows.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <ZebraExample mdxType="ZebraExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "vertical-lines",
      "style": {
        "position": "relative"
      }
    }}>{`Vertical lines`}<a parentName="h4" {...{
        "href": "#vertical-lines",
        "aria-label": "vertical lines permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Vertical lines can be used on tables that have a lot of columns or a lot of similar data (e.g. numbers).`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <VerticalExample mdxType="VerticalExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "dense-tables",
      "style": {
        "position": "relative"
      }
    }}>{`Dense tables`}<a parentName="h4" {...{
        "href": "#dense-tables",
        "aria-label": "dense tables permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`If your table has a large amount of data, you can use the `}<inlineCode parentName="p">{`dense`}</inlineCode>{` property to force mobile spacing on all screen sizes. This allows more data to fit into the view at the same time.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <DenseExample mdxType="DenseExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "sorting",
      "style": {
        "position": "relative"
      }
    }}>{`Sorting`}<a parentName="h4" {...{
        "href": "#sorting",
        "aria-label": "sorting permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Columns can be configured to be sortable which makes the sorting button appear after the column header. The sorting icon is different depending on the data type of the column (alphabetical or numeric/other).`}</p>
    <p>{`Currently, only one column can be sorted at a time.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SortingExample mdxType="SortingExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "selecting-rows",
      "style": {
        "position": "relative"
      }
    }}>{`Selecting rows`}<a parentName="h4" {...{
        "href": "#selecting-rows",
        "aria-label": "selecting rows permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS Table rows can be set to be selectable. This makes the first column to be a selection column. This also adds two (2) action buttons above the table; one button for selecting all rows and one button to clear selection.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SelectionExample mdxType="SelectionExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      